import React, {useEffect, useState} from "react"
import "./subNav.scss"


function SubNav({ children }){

    const [toggleActiveItem, setActiveItem] = useState('')
    
    const childrenList = React.Children.toArray(children);
    const idList = childrenList.map((el)=>{
        return el.props.to?.replace(/.*#/,'#')
    })
    const subNavScroll = (e) =>{
        idList.forEach((id, index)=>{
            
            const section = document.querySelector(id);

            if( section && window && window.scrollY > section.offsetTop){
                if(toggleActiveItem !== id.replace('#','.')){
                     setActiveItem(id.replace('#','.'))
                }
            } else if(section && window && window.scrollY == 0){
                setActiveItem(idList[0].replace('#','.'))
            }
        })
    }
    useEffect(() =>{
        if(toggleActiveItem){
            idList.forEach((id)=>{
                return document.querySelector(id.replace('#','.')).classList.remove("active")
            })
            document.querySelector(toggleActiveItem).classList.add("active")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleActiveItem])


    useEffect(() => {
        setActiveItem(idList[0]?.replace('#','.'))   
        window.addEventListener("scroll", subNavScroll);
        document.querySelector(".back-to-top").addEventListener("click", subNavScroll);
        return () => 
           window.removeEventListener("scroll", subNavScroll); 
      }, [])

    return(
        <div className="sub-nav" >
            <div className="container">
                <ul className="sub-nav__list">
                    {
                        React.Children.map(children, (child)=>{
                            return(
                                <li className={"sub-nav__link " + child.props.to.replace(/.*#/,'')}>{child}</li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default SubNav